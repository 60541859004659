import { Box, Grid, Typography, Container, List, ListItem } from "@mui/material";
import { neutral } from "../style/color";
import { Footer } from "./footer";
import Header from "./header";
export const PolicyPage = () => {

  return (
    <>
      <Header policy={true} />
      <Box sx={{ display: 'flex' }}>
        <Container >
          <Grid
            container
            direction="row"
            alignItems='flex-start'
          >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 5 }}>
              <Typography variant='subtitle1' sx={{ color: neutral[900] }} gutterBottom>
                Neta Foundation’s Privacy Policy
              </Typography>
              <Typography variant='smallCopyBold' sx={{ color: neutral[900], fontWeight: '700' }} gutterBottom>
                Last Updated: January 25, 2024
              </Typography>
              <Typography variant='bodyCopyRegular' sx={{ color: neutral[900], pt: 3 }} gutterBottom paragraph>
                <p> Neta Foundation (“Neta”) is a non-profit organization, and in the process of raising and deploying money in line with our mission, we care deeply about and respect your privacy and are committed to protecting it.</p>
                <p>This Privacy Policy explains how we collect, use, and protect the personal information you provide on our website and your rights.
                </p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  1. Information Collection
                </Typography>

                <p>When you sign up to be notified about our services, we ask for your personal information, including your name and email address. We collect this information for the purpose of communicating with you.
                </p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  2. Use of Information
                </Typography>

                <p>
                  Your information will be used exclusively for the purposes of notifying you about the launch and updates of our services, and engaging with you as a potential donor or partner. We will not use your information for any other purposes without your explicit consent.
                </p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  3. Data Protection
                </Typography>

                <p>We implement high standards of security measures to maintain the safety of your personal information.</p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  4. Sharing of Information
                </Typography>

                <p>
                Neta does not sell, share or trade your personal information. We do not share your personal information with third parties except as necessary to provide our services or as required by law.
                </p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  5. Your Rights
                </Typography>

                <p>
                  You have the right to:
                  <List>
                    <ListItem>
                      - Know what personal data is being collected about you.
                    </ListItem>
                    <ListItem>
                      - Know whether your personal data is sold or disclosed and to whom.
                    </ListItem>
                    <ListItem>
                      - Say no to the sale of personal data.
                    </ListItem>
                    <ListItem>
                      - Access your personal data.
                    </ListItem>
                    <ListItem>
                      - Request the deletion of your personal information.
                    </ListItem>
                  </List>
                </p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  6. Compliance with Laws
                </Typography>

                <p>We will disclose your personal information where required to do so by law or subpoena.</p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  7. Changes to Our Privacy Policy
                </Typography>

                <p> We may update this privacy policy periodically. Any changes will be posted on this page.</p>
                <Typography variant='bodyCopyBold' sx={{ color: neutral[900] }} gutterBottom>
                  8. Contacting Us
                </Typography>

                <p>If you have any questions or concerns about our privacy practices or your rights, please contact us via phone or email.</p>
              </Typography>

            </Grid>
          </Grid>
        </Container >

      </Box>

      <Footer /></>
  )
}
