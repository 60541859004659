import Button from "@mui/material/Button";

import {
  AppBar,
  Box,
  Toolbar,
} from "@mui/material";
import logo from './../assets/logo-black/logo-s.svg'

interface Props {
  policy?: boolean
  executeScroll?: () => void;
}
export const Header: React.FC<Props> = ({ policy, executeScroll }) => {

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          sx={{ boxShadow: "none", backgroundColor: 'warm.main' }}
          position="static"
          enableColorOnDark
        >
          <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box component="img" alt="Logo" src={logo} />
              {
                !policy &&
                <Button
                  onClick={executeScroll}
                  size="large"
                  variant="contained"
                  color="secondary"
                  sx={{ backgroundColor: "secondary.dark" }}

                >
                  Join Neta
                </Button>
              }
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
export default Header;
