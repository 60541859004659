import { createTheme } from "@mui/material/styles";
import { accent, neutral, primary, warm } from "./color";

import { ThemeOptions } from "@mui/material";

declare module '@mui/material/styles' {
  interface Palette {
    warm: Palette['primary'];
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    warm?: PaletteOptions['primary'];
    neutral?: PaletteOptions['primary'];
  }
}
declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    header:true;
    headeractive:true;
    footer:true;
  }
}
export const golbalTheme = createTheme({
  palette: {
    background: {
      default: warm[200]
    },
    mode: "light",
    primary: {
      main: primary[500],
      light: primary[200],
      dark: primary[800]
    },
    secondary: {
      main: accent[500],
      dark: accent[600],
      contrastText: "#FFF"
    },
    warm: {
      main: warm[500],
      light: warm[200],
      contrastText: neutral[900]
    },
    neutral: {
      main: neutral[900],
      dark: neutral[700],
      light: neutral[400],
      lighter: neutral[600]
    }
  },
  typography: {
    fontFamily: 'Quattrocento Sans',
    button: {
      textTransform: "none",
    },
    h0: {
      fontFamily: 'Source Serif 4',
      fontSize: '64px',
      fontWeight: '700',
      lineHeight: '64px',
    },
    h1: {
      fontFamily: 'Source Serif 4',
      fontSize: '40px',
      fontWeight: '700',
      lineHeight: '44px',
    },
    h2: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '40px',
      fontWeight: '700',
      lineHeight: '44px',
    },
    h3: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '34px',
      fontWeight: '700',
      lineHeight: '37.67px',
    },
    subtitle1: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '26.59px',
    },
    subtitle2: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '19.94px',
    },
    subtitle3: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '19.94px',
    },
    bodyCopyBold: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '17.73px',
    },
    bodyCopyRegular: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
    },
    smallCopyBold: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '15.51px',
    },
    smallCopy: {
      fontFamily: 'Quattrocento Sans',
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '13.3px',
    }
  },

} as ThemeOptions);

export const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {

        root: {
          ...golbalTheme.typography.subtitle3,
          position: "relative",
          display: "contents",
          color: golbalTheme.palette.neutral.main

        }
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {

        label: {
          ...golbalTheme.typography.bodyCopyBold,
          color: golbalTheme.palette.neutral.main

        }
      },
    },
    MuiFormLabel: {
      styleOverrides: {

        root: {
          ...golbalTheme.typography.bodyCopyBold,
          color: golbalTheme.palette.neutral.main

        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          ...golbalTheme.typography.bodyCopyBold,
          boxShadow: 'none'
        }
      },
      variants: [
        {
          props: { className: 'icon' },
          style: {
            backgroundColor: 'transparent',
              '& span svg  path': {
                fill: primary[500]

              },
              '& span svg  circle': {
                stroke: primary[500]
              }

          },
        },
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: golbalTheme.palette.primary.main,
            color: '#FFF'
          },
        },
        {
          props: { variant: 'header' },
          style: {
            ...golbalTheme.typography.bodyCopyBold,
            backgroundColor: 'transparent',
            color: golbalTheme.palette.neutral.main,
            display: 'block',
            "&:hover": {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'headeractive' },
          style: {
            ...golbalTheme.typography.bodyCopyBold,
            backgroundColor: 'transparent',
            color: golbalTheme.palette.primary.main,
            display: 'block',
            textDecoration: 'underline',
            "&:hover": {
              backgroundColor: 'transparent',
              textDecoration: 'underline',

            },
          },
        },
        {
          props: { variant: 'footer' },
          style: {
            ...golbalTheme.typography.bodyCopyBold,
            backgroundColor: 'transparent',
            color: golbalTheme.palette.neutral.main,
            display: 'block',
            "&:hover": {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: golbalTheme.palette.secondary.main,
            color: '#FFF'
          },
        },
      ],
    },
  },
}, golbalTheme);
