import React, { useEffect, useRef } from "react";
import { supabase } from "../App";
import { Box, Card, CardMedia, Grid, Typography, Button, Container, FormControl, InputLabel, OutlinedInput, FormHelperText, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent, CircularProgress } from "@mui/material";
import { neutral, warm } from "../style/color";
import { Footer } from "./footer";
import Header from "./header";
import home1 from './../assets/home1.png'
import home1Mobile from './../assets/home1-mobile.png'
import home2 from './../assets/home2.png'

import image1 from './../assets/image1.png'
import image2 from './../assets/image2.png'
import image3 from './../assets/image3.png'
import image4 from './../assets/image4.png'

export const HomePage = () => {
  const myRef = useRef<null | any>(null)
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  const [error, setError] = React.useState({
    name: false,
    email: false,
  })
  const [contactInformation, setContactInformation] = React.useState({
    name: "",
    email: "",
    start_offline: true
  })
  const [open, setOpen] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  useEffect(() => {
    console.log(loading)
  }, [loading])
  const handleChange = (key: string, value: any) => {
    setError({
      ...error,
      [key]: false
    })
    setContactInformation({
      ...contactInformation,
      [key]: value
    })
  }
  const handleError = (key: string) => {
    setError({
      ...error,
      [key]: true
    })
  }
  const submit = async (event: any) => {
    setLoading(true)
    event.preventDefault();
    const payload = { name: contactInformation.name, email: contactInformation.email, start_offline: contactInformation.start_offline as boolean }
    const { error } = await supabase
      .from('joiners')
      .insert([payload])
      .select()
    if (!error) {
      setLoading(false)
      setOpen(true)
      setContactInformation({
        name: "",
        email: "",
        start_offline: true
      })
    }
  }
  return (
    <>
      <Dialog sx={{ m: 2 }} fullWidth={true}
        maxWidth={'sm'} open={open} onClose={() => setOpen(false)}>

        <DialogContent sx={{ pl: 4, mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant='bodyCopyRegular'>
                Thank you, your information has been received and we will contact you as requested
              </Typography>
            </Grid>
            <Grid item xs={12} sm={11}>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="contained" color='primary' size='large' onClick={() => setOpen(false)} type="submit" >
                  Got it
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>



      </Dialog>
      <Header executeScroll={executeScroll} />
      <Box sx={{ display: 'flex' }}>
        <Box >
          <Card sx={{ position: 'relative', display: { xs: 'block', sm: 'none', md: 'none' } }}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              height="auto"
              image={home1Mobile}
              title="Contemplative Reptile"
            />
            <Grid sx={{
              position: 'absolute',
              top: '20%',
              width: '100%',
              p: { md: 6 },
              pr: { md: 0 },

            }} container>
              <Grid sx={{ m: { xs: 4, sm: 4 } }} item md={6}>

                <Typography variant="h2" color={"white"} gutterBottom>
                  Where philanthropy meets investing
                </Typography>
                <Typography variant="bodyCopyBold" color={"white"} >
                  Neta Foundation offers the first philanthropic platform to discover and invest in impactful research and technology
                </Typography>
                <Box sx={{
                  my: 4
                }}>
                  <Button variant='outlined' sx={{ color: 'white', borderColor: 'white' }} size='large' onClick={executeScroll}>Join Neta</Button>
                </Box></Grid></Grid>

          </Card>
          <Card sx={{ position: 'relative', display: { xs: 'none', sm: 'block', md: 'block' } }}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              height="auto"
              image={home1}
              title="Contemplative Reptile"
            />
            <Grid sx={{
              position: 'absolute',
              top: '20%',
              width: '100%',
              p: { md: 6 },
              pr: { md: 0 },
            }} container>
              <Grid sx={{ m: { xs: 4, sm: 4, md: 2 } }} item xs={12} sm={12} md={6} lg={6}>

                <Typography variant="h2" color={"white"} gutterBottom>
                  Where philanthropy meets investing
                </Typography>
                <Typography variant="bodyCopyBold" color={"white"} >
                  Neta Foundation offers the first philanthropic platform to discover and invest in impactful research and technology
                </Typography>
                <Box sx={{
                  my: 4
                }}>
                  <Button variant='outlined' sx={{ color: 'white', borderColor: 'white' }} size='large' onClick={executeScroll}>Join Neta</Button>
                </Box></Grid></Grid>

          </Card>
          <Box sx={{ py: { xs: 7, sm: 7, md: 13 }, px: { xs: 3, sm: 3, md: 26 } }}>
            <Grid
              container
              direction="row"
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item md={6}>
                <Typography variant="h1" sx={{ color: neutral[900] }} gutterBottom>
                  Ready to redefine the way you give and invest?
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='bodyCopyRegular' sx={{ color: neutral[900] }} gutterBottom>
                  Neta is a charitable asset manager where your generosity goes beyond traditional giving and investing. We offer a unique blend of charitable opportunities and innovative investments, making it easier for donors to invest in solutions to issues that they care about. We bring granting and investing so close to each other, that we created a new category: GrantVestment™
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{
            py: { xs: 7, sm: 7, md: 13, lg: 13 }, px: { xs: 3, sm: 3, md: 26, lg: 26 }, backgroundImage: `url(${home2})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "auto",
          }}>
            <Grid
              container
              direction="row"
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={6} md={5} lg={5} sx={{ p: 5, border: '1px solid white', borderRadius: '8px' }}>
                <Typography variant='subtitle1' sx={{ color: 'white' }} gutterBottom>
                  Neta
                </Typography>
                <Typography variant='subtitle3' sx={{ color: 'white' }} gutterBottom>
                  /nɛtə/
                </Typography>
                <Typography variant='bodyCopyRegular' sx={{ color: 'white', pt: 3 }} gutterBottom paragraph>
                  meaning "seedling", often associated with growth, new beginnings, and the potential for flourishing
                </Typography>

              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: '100%', backgroundColor: 'white' }}>
            <Container >
              <Box sx={{ py: 5, width: '100%' }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={5}
                >
                  <Grid item md={12} sx={{ textAlign: "center", my: 2 }}>
                    <Typography variant="h3" gutterBottom>
                      What makes us unique
                    </Typography>
                    <Typography variant="bodyCopyRegular">
                      At Neta we offer investment opportunities into early-stage pre-commercialization technology coming out of leading universities and research institutions to better human well-being.                      </Typography>
                  </Grid>
                  <Grid sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }} item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image1}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid sx={{ m: { xs: 4, sm: 4 } }} item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Innovative Philanthropy
                    </Typography>
                    <Typography variant="bodyCopyRegular" paragraph>
                      Explore a world at Neta where your contributions drive innovation and create lasting change. We redefine philanthropy by seamlessly blending giving with strategic investments in solutions that matter: GrantVestment™</Typography>
                  </Grid>
                  <Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image1}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image2}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid sx={{ m: { xs: 4, sm: 4 } }} item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Early-Stage Technology
                    </Typography>
                    <Typography variant="bodyCopyRegular" paragraph>
                      Discover exclusive opportunities to invest in emerging technologies from leading research institutions. Neta provides a curated selection, ensuring your investments align with your values and contribute to meaningful solutions.
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }} md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image3}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid sx={{ m: { xs: 4, sm: 4 } }} item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Tailored Giving Options
                    </Typography>
                    <Typography variant="bodyCopyRegular">
                      Choose the level of involvement that suits you best. Whether hands-on decisions through our Donor Advised Fund or a more hands-off approach with our Non-Profit Funds, Neta ensures your philanthropy reflects your unique vision.
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image3}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image4}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid sx={{ m: { xs: 4, sm: 4 } }} item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Evergreen Impact
                    </Typography>
                    <Typography variant="bodyCopyRegular" paragraph>
                      Experience the power of evergreen structures. Returns on investments contribute to a perpetuating cycle of impact, ensuring your contributions continue making a difference over the long term.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
          <Box sx={{ py: 7, px: { xs: 3, sm: 12, md: 12 } }} >
            <Box sx={{ py: { xs: 7, sm: 10, md: 10 }, px: { xs: 3, sm: 15, md: 15 }, backgroundColor: warm[500], borderRadius: '8px' }}>
              <Grid
                container
                direction="row"
                alignItems='center'
                spacing={10}
              >
                <Grid item md={7}>
                  <Typography variant='subtitle1' sx={{ color: neutral[900] }} gutterBottom>
                    Our online portal will be ready soon
                  </Typography>
                  <Typography variant='bodyCopyRegular' sx={{ color: neutral[900], pt: 3 }} gutterBottom paragraph>
                    We're creating an amazing online experience. In the meantime, we're operating offline. Give us your name and email to join our waitlist or start your process offline.
                  </Typography>
                </Grid>
                <Grid ref={myRef} item md={5}>
                  <Box component="form"
                    autoComplete="off"
                    onSubmit={submit} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={10}>

                        <FormControl error={error.name} fullWidth>
                          <InputLabel shrink={false}>Name</InputLabel>
                          <OutlinedInput size='small' name="name"
                            value={contactInformation.name}
                            onChange={(event) => handleChange("name", event.target.value)}
                            onInvalid={(event) => handleError('name')}
                            type="text" required />
                          {error.name && <FormHelperText color={'red'}>Name is required</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={10}>

                        <FormControl error={error.email} fullWidth>
                          <InputLabel shrink={false}>Email address</InputLabel>
                          <OutlinedInput size='small' name="email"
                            value={contactInformation.email}
                            onChange={(event) => handleChange("email", event.target.value)}
                            onInvalid={(event) => handleError('email')}
                            type="email" required />
                          {error.email && <FormHelperText color={'red'}>Email is required</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant='subtitle3' sx={{ color: neutral[900] }} gutterBottom>
                          When would you like us to contact you?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControl>
                          <RadioGroup

                            name="controlled-radio-buttons-group"
                            value={contactInformation.start_offline}
                            onChange={(event) => handleChange("start_offline", event.target.value)}
                          >
                            <FormControlLabel value={true} control={<Radio size='small' />}
                              label={<Typography variant='bodyCopyRegular' sx={{ color: neutral[900] }} >
                                Now, to start offline </Typography>} />
                            <FormControlLabel value={false} control={<Radio size='small' />} label={<Typography variant='bodyCopyRegular' sx={{ color: neutral[900] }} >
                              When the online portal is ready </Typography>} />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button disabled={loading} startIcon={loading && <CircularProgress size={20} color="inherit" />} variant="contained" color='primary' size='large' type="submit"   >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

      </Box>

      <Footer /></>
  )
}
