import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { warm } from "../style/color";
import logo  from './../assets/logo-black/logo-s.svg'







export const Footer = () => {

    return (
        <Box sx={{ p: 5, backgroundColor: "warm.main" }}>


            <Grid alignItems="flex-start" container spacing={4} >
                <Grid item xs={12} sm={2}>
                    <Box
                        component="img"
                        alt="Logo"
                        src={logo}
                    />
                </Grid>

                <Grid item xs={12} sm={8}>
                <Typography variant="bodyCopyBold" paragraph>Contact us</Typography>
        <Typography sx={{ m: 0 }} variant="bodyCopyRegular" paragraph>+1 (646) 600-9433 </Typography>
        <Typography sx={{ m: 0 }} variant="bodyCopyRegular" paragraph>contact@netafoundation.org</Typography>
        <Typography sx={{ m: 0 }} variant="bodyCopyRegular" paragraph>2248 Broadway #1702 New York, NY 10024 </Typography>

                </Grid>
            </Grid>
            <Grid sx={{ mt: 5 }} justifyContent="flex-end" alignItems="center" container >
                <Grid item xs={12} sm={12}>
                <Box sx={{ color: warm[900], flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  
                  <Button
                    variant="footer"
                    href='/policy'
                    target='_blank'
                  >
                    Privacy Policy 
                  </Button>
                </Box>
                </Grid>
            </Grid>
            <Grid sx={{ mt: 2, textAlign:"center" }} justifyContent="center" alignItems="center" container >
                <Grid item xs={12} sm={12}>
                <Typography variant="smallCopy" gutterBottom>
                © 2024 Neta Foundation, Inc. All rights reserved            </Typography>
                </Grid>
            </Grid>
        </Box>




    );
}







