import { createClient } from '@supabase/supabase-js'
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { theme } from "./style/theme";
import { HomePage } from './components/homepage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PolicyPage } from './components/policy';


const supabaseUrl: string = "https://jfxpedtmxhkuyhxalfko.supabase.co"
const supabaseAnonKey: string = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpmeHBlZHRteGhrdXloeGFsZmtvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDU5NDM3OTEsImV4cCI6MjAyMTUxOTc5MX0.NOXUPSsL74vH37cE_hOEa3v-bfvuxcNC6OGr2lC98p8"

export const supabase = createClient(supabaseUrl!, supabaseAnonKey)
function App() {

  return (
    <div className="App">
      {/* Mui provider */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" Component={HomePage} />
            <Route path="/policy" Component={PolicyPage} />


          </Routes>

        </BrowserRouter>


      </ThemeProvider>


    </div>
  );
}
export default App;

